import * as React from 'react';
import {  useForm } from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import './edit.css';

import obtieneDatos from './obtieneDatos'
import regresaBienFecha from './regresaBienFecha';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Legajo from './generaLegajo';
var muestraEdita=false;
const cookies=new Cookies();

function Loguin() {
  if(!cookies.get('id',{path:"/"})){

    window.location.href='./';
}else{
 if(cookies.get('Abiertas',{path:"/"})==="true"){
   muestraEdita=true;
 
 }
}
  
  const usuario=  obtieneDatos()

  
 const navitate = useNavigate()
 
const legajoNro='Número de Legajo de Inscripción (NLI): '+ usuario.id+"-"+usuario.dni+"-R2024EP";
const nombreArchivo = legajoNro;
  const {handleSubmit} = useForm({});

  
  const onSubmit = () => {
    
    navitate('/new')
    };

  const volver=()=>
  {
    
    navitate('/')
  }
  
  const editaLegajo=()=>
  {
    
    navitate('/new')
  }

  
  const imprimeLegajo=()=>
  {
   window.open('/imprime')
  }


  return (
    <div > <h3 style={{textAlign:"center"}}>Menu del Postulante Preinscripto ciclo 2024-2025 {"(REAPERTURA)"} </h3>
    <div className='form__container_edit' style={{marginTop:"-5px"}}>
     
      <form onSubmit={handleSubmit(onSubmit)} className='form_edit_back'>
        <section className="form__group">
          <h3 className="edit__title">Usted se encuentra inscripto bajo {legajoNro}</h3>
          <h3 className="edit__subtitle">Se inscribio en fecha {regresaBienFecha(usuario.creado)}</h3>
          {usuario.creado!==usuario.modificado&&( <h3 className="edit__subtitle">Modifico sus datos en fecha {regresaBienFecha(usuario.modificado)}</h3>)}
          <h3 className="edit__sub-title">Nombre: {usuario.nombres}</h3>
          <h3 className="edit__sub-title">Apellido: {usuario.apellido}</h3>
          <h3 className="edit__sub-title">DNI: {usuario.dni}</h3>
          
        </section>
          <section>
        
   <PDFDownloadLink document={<Legajo />} fileName={nombreArchivo}>
   <div className="contenedor_boton_edit">
          <input onClick={imprimeLegajo} type="button" value="Imprima su legajo"  id="imprime" />
          
        </div>
    </PDFDownloadLink>
        
        {muestraEdita?(<div className="contenedor_boton_edit">
          <input type="button" value="Editar Legajo"  id="edita" onClick={editaLegajo}/>
          
        </div>):""}
        <div className="contenedor_boton_edit">
          <input type="button" value="Salir"  id="edita" onClick={volver}/>
          
        </div>
        </section>
      </form>

      <footer>
        <div className="edit__footer">
          <p>DTyD 2024</p>
        </div>
      </footer>
      
    </div></div>
  );
}
export default Loguin;
